<template>
  <div class="container absolute inset-x-0 bottom-0 flex flex-col items-center justify-center max-w-md px-4 pb-24 mx-auto">
    <button @click="login" class="flex items-center justify-center w-full max-w-sm px-4 py-2 text-sm tracking-wider uppercase bg-gray-300 rounded-md hover:bg-gray-400 dark:text-gray-300 dark:bg-gray-900 dark:hover:bg-black focus:outline-none">
      <img src="../assets/google.png" alt="G" class="w-6 h-6 mr-2">
      Anmelden
    </button>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  methods: {
    login() {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'de'
      firebase.auth().signInWithRedirect(provider).then(res => {
        console.log('success:', res)
      }).catch(e => {
        console.log('error:', e)
      })
    }
  }
}
</script>
